import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterState,
  RouterStateSnapshot,
} from '@angular/router';
import {UserApiService} from '@shared/services/user-api.service';
import {inject} from '@angular/core';
import {UserPrivilege} from '@shared/model/user';

export const permissionGuard: CanActivateFn = async (route, state) => {
  const currentRoute = new URL(`http://host${state.url}`).pathname; // strip query params
  const userService = inject(UserApiService);
  const privileges = await new Promise<UserPrivilege[]>(resolve => {
    userService.getPrivileges().subscribe(privileges => {
      resolve(privileges);
    });
  });
  const websiteId = await new Promise<string | null>(resolve => {
    userService.getWebsiteId().subscribe(websiteId => {
      resolve(websiteId);
    });
  });

  return userAllowedToViewPage(currentRoute, privileges, websiteId === null);
};

export const getUserPermissions = (privileges: any[]): string[] => {
  return privileges.map(privilege => privilege.name);
};

export const userAllowedToViewPage = (
  page: string | undefined,
  privileges: any[],
  isEditingGlobalWebsite: boolean = false,
) => {
  const permissions = getUserPermissions(privileges);

  if (page === '/') {
    return true;
  } else if (
    permissions.includes('EDIT_WEBSITE_MASTER_DATA') &&
    page?.startsWith('/website-master-data')
  ) {
    return true;
  } else if (
    permissions.includes('MANAGE_USERS') &&
    page?.startsWith('/user')
  ) {
    return true;
  } else if (permissions.includes('EDIT_USPS') && page?.startsWith('/usps')) {
    return true;
  } else if (
    permissions.includes('EDIT_PAGES') &&
    (page?.startsWith('/menu') || page?.startsWith('/page'))
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_LICENSE_CLASSES') &&
    page?.startsWith('/license-class')
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_LICENSE_CLASS_CATEGORIES') &&
    page?.startsWith('/license-class-category')
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_COURSES') &&
    page?.startsWith('/course')
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_TEAM') &&
    !isEditingGlobalWebsite &&
    page?.startsWith('/team-member')
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_LOCATIONS') &&
    !isEditingGlobalWebsite &&
    page?.startsWith('/location')
  ) {
    return true;
  } else if (permissions.includes('EDIT_NEWS') && page?.startsWith('/news')) {
    return true;
  } else if (
    permissions.includes('EDIT_BLOG') &&
    page?.startsWith('/blog-post')
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_PARTNERS') &&
    page?.startsWith('/partner')
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_VEHICLES') &&
    page?.startsWith('/vehicle')
  ) {
    return true;
  } else if (
    permissions.includes('EDIT_WEBSITES') &&
    isEditingGlobalWebsite &&
    page?.startsWith('/website')
  ) {
    return true;
  }

  return false;
};
