import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of, switchMap} from 'rxjs';
import {environment} from '@environments/environment';
import {Website} from "@shared/model/website";

@Injectable({
  providedIn: 'root',
})
export class WebsiteService {
  constructor(
    private httpClient: HttpClient,
  ) {}

  public listAll(): Observable<Website[]> {
    return this.httpClient.get(`${environment.cmsApiUrl}/internal/website`).pipe(
      map((websites: any) => {
        return (websites || []).map((website: any) => new Website(website));
      }),
    );
  }

}
