import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {brynIconCritical, brynIconExit, BrynIconRegistry, BrynUIModule} from "brynui";
import {HttpClientModule} from "@angular/common/http";
import {OAuthModule} from "angular-oauth2-oidc";
import {environment} from "../environments/environment";
import {DeleteConfirmationModalComponent} from "./delete-confirmation-modal/delete-confirmation-modal.component";
import { NoPermissionsOverlayComponent } from './shared/components/no-permissions-overlay/no-permissions-overlay.component';
import {Router} from "@angular/router";
import * as Sentry from "@sentry/angular";


@NgModule({
  declarations: [
    AppComponent,
    DeleteConfirmationModalComponent,
    NoPermissionsOverlayComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrynUIModule.forRoot(),
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [`${environment.apiUrl}/api`],
        sendAccessToken: true,
      }
    }),
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private iconsRegistry: BrynIconRegistry) {
    iconsRegistry.registerIcons([brynIconExit, brynIconCritical]);
  }
}
