import {Component} from '@angular/core';
import {UserApiService} from '@shared/services/user-api.service';
import {Website} from "@shared/model/website";
import {OAuthService} from "angular-oauth2-oidc";
import {WebsiteService} from "@shared/services/website.service";

@Component({
  selector: 'app-no-permissions-overlay',
  templateUrl: './no-permissions-overlay.component.html',
  styleUrls: ['./no-permissions-overlay.component.sass'],
})
export class NoPermissionsOverlayComponent {
  public userHasPermissions = true;
  public permissionsRequested = false;
  public showRequestPermissions = false;
  public websites: Website[] = [];
  public website: Website | undefined;


  constructor(private userService: UserApiService, private oauthService: OAuthService, private websiteService: WebsiteService) {
    this.userService.getUser().subscribe(user => {
      this.userHasPermissions = user && user.privileges.length > 0;

      this.websiteService
          .listAll()
          .subscribe(
              websites => (this.websites = [...websites]),
          );
    });
  }

  public websiteToLabel(website: Website): string {
    return website.name;
  }

  public setWebsite(website: Website | undefined) {
    this.website = website;

  }

  public logout() {
    this.oauthService.logOut();
  }

  public requestPermissions() {
    if (!this.website) {
      return;
    }

    this.userService.requestPermissions(this.website.id!).subscribe(() => {
      this.permissionsRequested = true
    });
  }
}
