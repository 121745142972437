import {Component, OnInit} from '@angular/core';
import {Menu, MenuItem, ModalService} from 'brynui';
import {OAuthService} from 'angular-oauth2-oidc';
import {authCodeFlowConfig} from './shared/oauthConfig';
import {ActivatedRoute, Router} from '@angular/router';
import {filter} from "rxjs";
import {UserApiService} from "@shared/services/user-api.service";
import {User} from "@shared/model/user";
import {Website} from "@shared/model/website";
import {userAllowedToViewPage} from "../guards/permission-guard";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
    public website: Website | undefined;
    public websites: Website[] | undefined;
    public user: { email: string; name: string } = {email: '', name: 'Academy'};
    public accountActions = [{name: 'Abmelden', cb: () => this.logout()}];
    public canSwitchWebsites = false;
    public menu: Menu | undefined = undefined // Menu.fromItems([]);
    protected readonly undefined = undefined;
    private privileges: any[] = [];
    private userDataLoaded: boolean = false;

    constructor(
        private router: Router,
        private oauthService: OAuthService,
        private userService: UserApiService,
        private modalService: ModalService,
        private activatedRoute: ActivatedRoute,
    ) {

        // Redirect to initially requested page
        this.oauthService.events.subscribe(event => {
            if (event.type === 'token_received' && this.oauthService.state) {
                const redirect_uri = decodeURIComponent(this.oauthService.state);
                if (redirect_uri && redirect_uri !== '/') {
                    this.router.navigateByUrl(redirect_uri);
                }
            }
        });

        // Remember the selected configuration
        this.oauthService.configure(authCodeFlowConfig);
        this.oauthService.setupAutomaticSilentRefresh();

        // Automatically load user profile
        this.oauthService.events
            .pipe(
                filter(e => {
                    return e.type === 'token_received';
                }),
            )
            .subscribe(_ => {
                this.onAuthenticated();
            });

        this.oauthService.events
            .pipe(
                filter(e => {
                    return e.type === 'token_refresh_error';
                }),
            )
            .subscribe(tokenRefreshError => {
                this.oauthService.refreshToken();
            });


        this.oauthService.loadDiscoveryDocumentAndTryLogin().then(_ => {
            if (
                !this.oauthService.hasValidIdToken() ||
                !this.oauthService.hasValidAccessToken()
            ) {
                const request_uri = window.location.pathname + window.location.search;
                const additionalState = (request_uri !== '/' ? request_uri : undefined);
                this.oauthService.initLoginFlow(additionalState);
            } else {
                this.onAuthenticated();
            }
        });

        this.userService.getUser().subscribe((user: User) => {
            this.website = user.website;
            this.user = {email: user.email, name: user.name};
            this.privileges = user.privileges;

            this.canSwitchWebsites = user.canSwitchWebsites

            this.initMenu();

            this.activatedRoute.queryParams.subscribe(params => {
                const {property, ...rest} = params

                if (!property) {
                    return
                }
                if (user.website?.id === property) {return}

                this.setWebsite(property);

                this.router.navigate([], { queryParams: rest });
            });
        });
    }

    public logout() {
        this.oauthService.logOut();
    }

    public websiteToLabel(website: Website): string {
        return website.name;
    }

    public setWebsite(websiteId: string | undefined): void {
        this.userService.setUserWebsite(websiteId ?? null).subscribe(res => {
            location.reload();
        });
    }

    ngOnInit(): void {
    }

    initMenu() {
        this.menu = Menu.fromItems([
            new MenuItem({
                title: 'Dashboard',
                routerLink: '/',
            }),
            new MenuItem({
                title: 'Nutzer',
                routerLink: '/user',
                activePathRegex: /^\/user(\/[^\/]+)?$/,
                disabled: !userAllowedToViewPage(
                    '/user',
                    this.privileges,
                ),
            }),
            new MenuItem({
                title: 'Leads',
                routerLink: '/lead',
            }),
            new MenuItem({
                title: 'Führerschein-Anmeldungen',
                routerLink: '/registration',
            }),
            new MenuItem({
                activePathRegex: /^\/(course)(.*)?$/,
                title: 'Kurse',
                routerLink: '/course',
                children: [
                    new MenuItem({
                        title: 'Kursanmeldungen',
                        routerLink: '/course/registration',
                    }),
                ],
            }),

        ])
    }

    private onAuthenticated(): void {
        if (this.userDataLoaded) {
            return;
        }
        this.userDataLoaded = true;
        this.userService.loadUser();
        this.userService
            .listAllWebsites()
            .subscribe(
                websites => (this.websites = websites),
            );
    }
}
