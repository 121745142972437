export class Website {
  public id: string | undefined;
  public domain: string;
  public name: string;
  public legalName: string;

  constructor(el: any = {}) {
    this.id = el.id;
    this.domain = el.domain ?? '';
    this.name = el.name || '';
    this.legalName = el.legalName || '';
  }

  public toJson(): any {
    return {
      id: this.id,
      domain: this.domain,
      name: this.name,
      legalName: this.legalName,
    };
  }
}
